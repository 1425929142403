/*
Original source : https://codepen.io/wheatup/pen/KKZRjaZ 
*/

.grid-container {
    --grid: 10rem;
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    transform-style: preserve-3d;
    perspective: 100rem;
    animation: rotate 100s linear infinite forwards;
    overflow: hidden;

    .title-top {
        transform-style: preserve-3d;
        transform-origin: top center;
        transform: perspective(500px) rotateX(-50deg) rotateY(0deg) rotateZ(0deg) scaleY(1.5);
    }

    .title-bottom {
        transform-style: preserve-3d;
        transform-origin: bottom center;
        transform: perspective(500px) rotateX(50deg) rotateY(0deg) rotateZ(0deg) scaleY(1.5);
    }

	
	.plane {
		--dir: 1;
		width: 300%;
		height: 150%;
		min-height: 70rem;
		position: absolute;
		bottom: 0;
		transform-style: preserve-3d;
		transform-origin: bottom center;
		transform: translateX(-50%) rotateX(85deg);
		
		&:last-child {
			--dir: -1;
			top: 0;
			transform-origin: top center;
			transform: translateX(-50%) rotateX(-85deg);
			
			& > * {
				&::after {
					background-image: linear-gradient(to top, rgba(0, 0, 0, 255) var(--grid), rgba(255, 255, 255, 0));
				}
			}
		}
		
		& > * {
			transform-style: preserve-3d;
			height: 100%;
			width: 100%;
			position: absolute;
			
			&::before, &::after {
				content: '';
				display: block;
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}

			&::before {
				background-image: repeating-linear-gradient(to left, #0070F0, #0070F0 4px, transparent 4px, transparent var(--grid)), 
					repeating-linear-gradient(to bottom, #0070F0, #0070F0 4px, transparent 4px, transparent var(--grid));
				animation: move 1s linear infinite forwards;
			}

			&::after {
				background-image: linear-gradient(to bottom, rgba(0, 0, 0, 255) var(--grid), rgba(0, 0, 0, 0));
				z-index: 1;
				transform: translateZ(1px);
			}
		}
	}
}

@keyframes move {
	from {
		transform: translateY(0px);
	}
	
	to {
		transform: translateY(calc(var(--grid) * var(--dir)));
	}
}
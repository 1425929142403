/* A bit overkill to import all the font sizes*/
@import '@fontsource/poppins/100.css';
@import '@fontsource/poppins/100-italic.css';
@import '@fontsource/poppins/200.css';
@import '@fontsource/poppins/200-italic.css';
@import '@fontsource/poppins/300.css';
@import '@fontsource/poppins/300-italic.css';
@import '@fontsource/poppins/400.css';
@import '@fontsource/poppins/400-italic.css';
@import '@fontsource/poppins/500.css';
@import '@fontsource/poppins/500-italic.css';
@import '@fontsource/poppins/600.css';
@import '@fontsource/poppins/600-italic.css';
@import '@fontsource/poppins/700.css';
@import '@fontsource/poppins/700-italic.css';
@import '@fontsource/poppins/800.css';
@import '@fontsource/poppins/800-italic.css';
@import '@fontsource/poppins/900.css';
@import '@fontsource/poppins/900-italic.css';
@font-face {
    font-family: 'Powerr';
    src: url('../public/fonts/powerr/powerr-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
